import React from 'react'
import { navigate, Link } from 'gatsby'
import { Router } from '@reach/router'
import Events from '../components/Events'
import Layout from '../components/layout'
// import Event from './events/show'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Link to="/app/events">Events</Link>
      </Layout>
    )
  }
}

export default IndexPage
